export default function confirmEnglishText(){

  // 早期リターン
  const submit = document.querySelector('.submitBtn');
  if(!submit) return;


  // =================
  // 英語表記を作成
  // =================

  // 会社名
  const valueNameElement_0 = document.createElement('p');
  valueNameElement_0.classList.add('en');
  valueNameElement_0.innerHTML = 'Company name<span class="red">*</span>';

  // お名前
  const valueNameElement_1 = document.createElement('p');
  valueNameElement_1.classList.add('en');
  valueNameElement_1.innerHTML = 'Name<span class="red">*</span>';

  // 電話番号
  const valueNameElement_2 = document.createElement('p');
  valueNameElement_2.classList.add('en');
  valueNameElement_2.innerHTML = 'Phone number<span class="red">*</span>';

  // メールアドレス
  const valueNameElement_3 = document.createElement('p');
  valueNameElement_3.classList.add('en');
  valueNameElement_3.innerHTML = 'Mail address<span class="red">*</span>';

  // お問い合わせ内容
  const valueNameElement_4 = document.createElement('p');
  valueNameElement_4.classList.add('en');
  valueNameElement_4.innerHTML = 'Inquiry<span class="red">*</span>';


  // 発火
  submit.addEventListener('click', ()=> {

    const valueName_0 = document.getElementById('th_mfp_element_0');
    const valueName_1 = document.getElementById('th_mfp_element_1');
    const valueName_2 = document.getElementById('th_mfp_element_2');
    const valueName_3 = document.getElementById('th_mfp_element_3');
    const valueName_4 = document.getElementById('th_mfp_element_4');

    valueName_0.appendChild(valueNameElement_0);
    valueName_1.appendChild(valueNameElement_1);
    valueName_2.appendChild(valueNameElement_2);
    valueName_3.appendChild(valueNameElement_3);
    valueName_4.appendChild(valueNameElement_4);

  });
}

