export default function menu() {

  // 途中からボタンを表示
  $(window).scroll(function () {
    if ($(this).scrollTop() > 700) {
      $('#pageTop').fadeIn();
    }
    else {
      $('#pageTop').fadeOut();
    }
  });

  // フッター手前で停止
  $(window).on("scroll", function () {
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    var footHeight = $("#footer").innerHeight();
    var pagetopHeight = $("#pageTop").height();
    var pagetopHulf = pagetopHeight / 2;

    if (window.matchMedia('(min-width: 1366px)').matches) {

      if (scrollHeight - scrollPosition <= footHeight - (pagetopHulf + 60)) {
        $("#pageTop").css({
          position: "absolute",
          bottom: footHeight - pagetopHulf,
        });
      } else {
        $("#pageTop").css({
          position: "fixed",
          bottom: "120px",
        });
      }

    } else if (window.matchMedia('(min-width: 961px').matches) {

      if (scrollHeight - scrollPosition <= footHeight - (pagetopHulf + 50)) {
        $("#pageTop").css({
          position: "absolute",
          bottom: footHeight - pagetopHulf,
        });
      } else {
        $("#pageTop").css({
          position: "fixed",
          bottom: "100px",
        });
      }

    } else {

      if (scrollHeight - scrollPosition <= footHeight - (pagetopHulf + 44)) {
        $("#pageTop").css({
          position: "absolute",
          bottom: footHeight - pagetopHulf,
        });
      } else {
        $("#pageTop").css({
          position: "fixed",
          bottom: "44px",
        });
      }

    }
  });


  // $(function () {
  //   const pageTop = $("#pageTop");
  //   pageTop.hide();
  //   $(window).scroll(function () {
  //     if ($(this).scrollTop() > 100) {
  //       pageTop.fadeIn();
  //     } else {
  //       pageTop.fadeOut();
  //     }
  //   });
  //   pageTop.click(function () {
  //     $("body,html").animate(
  //       {
  //         scrollTop: 0,
  //       },
  //       500
  //     );
  //     return false;
  //   });
  //   // フッター手前でストップ
  //   $("#pageTop").hide();
  //   $(window).on("scroll", function () {
  //     var scrollHeight = $(document).height();
  //     var scrollPosition = $(window).height() + $(window).scrollTop();
  //     var footHeight = $("footer").innerHeight();
  //     if (scrollHeight - scrollPosition <= footHeight) {
  //       // ページトップボタンがフッター手前に来たらpositionとfixedからabsoluteに変更
  //       $("#pageTop").css({
  //         position: "absolute",
  //         bottom: footHeight,
  //       });
  //     } else {
  //       $("#pageTop").css({
  //         position: "fixed",
  //         bottom: "0",
  //       });
  //     }
  //   });
  // });
}
