/* Modules
==================== */

// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// ページトップ
import pagetop from "./modules/pagetop";
pagetop();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();

// 確認画面の英語表記
import confirmEnglishText from "./modules/confirmEnglishText";
confirmEnglishText();

/* Script
==================== */
